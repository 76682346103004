import React, { useState, useEffect, useLayoutEffect } from 'react'
import './todo.css'
import Open from '../../media/open.webp'

import {toDoCollectionRef, mensagensCollectionRef} from '../../services/fireref'
import { db } from '../../libs/firebase'
import { addDoc, onSnapshot, query, doc,  orderBy, updateDoc, deleteDoc } from "firebase/firestore";
import { auth } from '../../libs/firebase'
import { getAuth, onAuthStateChanged } from 'firebase/auth'


function ToDo() {
    const [ativo1, setAtivo1]= useState(false);
    const [ativo2, setAtivo2]= useState(false);
    const [ativo3, setAtivo3]= useState(false);

    const [addToDO, setAddToDo]=useState(false);

    const dia =new Date().toISOString().slice(0, 10)


    const [mensagemToDO, setMensagemToDO]=useState('')
    //const [user, setUser]=useState('')
    const [usuario, setUsuario]=useState('')



    const handleOpen1 = ()=>{
        setAtivo1(true)
        setAtivo2(false)
        setAtivo3(false)
        }
    const handleClose1 = ()=>{
        setAtivo1(false)
    }
     const handleOpen2 = ()=>{
        setAtivo1(false)
        setAtivo2(true)
        setAtivo3(false)
        }
    const handleClose2 = ()=>{
        setAtivo2(false)
    }
    const handleOpen3 = ()=>{
        setAtivo1(false)
        setAtivo2(false)
        setAtivo3(true)
        }
    const handleClose3 = ()=>{
        setAtivo3(false)
    }

     
    //pegando a fazer e mensagens
    const [listaToDo, setListaTodo]=useState([])
    const [chat, setChat]=useState([])

  useEffect(()=>{
    
    const q = query(toDoCollectionRef, orderBy("dia", "desc"));
    const change = onSnapshot(q, snapshot=>{
      setListaTodo(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
    })

     const r = query(mensagensCollectionRef, orderBy("dia", "desc"));
    const change2 = onSnapshot(r, snapshot=>{
      setChat(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
    })

    return()=>{change(); change2()}

},[]);
useLayoutEffect(()=>{
    const sign=()=>{
      const auth = getAuth();
      const user = auth.currentUser;
      if (user !== null) {
       if(user.email=== 'luisesnal@nexointell.com')
       {setUsuario('Luis')}else{setUsuario('Antônio')}
      }
}
    
    
    return()=>{sign()}
},[listaToDo])

 //upando a fazer
  const handleAddToDO =async(e)=>{
    e.preventDefault();

    await addDoc( toDoCollectionRef, {
        dia,
        mensagemToDO,
        status:true,
        user:usuario
      }
      );

    setAddToDo(false)

  }

  //upando a lembrete
  const [addLembrete, setAddLembrete]=useState(false)
  const [textoLembrete,setTextoLembrete]=useState('')
  const handleAddLembrete =async(e)=>{
    e.preventDefault();

    await addDoc( mensagensCollectionRef, {
        dia,
        texto:textoLembrete,
        user:usuario
      }
      );

    setAddLembrete(false)

  }
  

//trocando de listas todo
const handeStatus1= async(e, id)=>{
    e.preventDefault()
    const toDoDoc = doc(db, 'todo', id)
    const newField = ({status:false})
    await updateDoc(toDoDoc , newField)
  }

const handeStatus2= async(e, id)=>{
    e.preventDefault()
    const toDoDoc = doc(db, 'todo', id)
    const newField = ({status:true})
    await updateDoc(toDoDoc , newField)
  }

  //deletando todo
const handeDeleteTodo= async(e, id)=>{
    e.preventDefault()
    const toDoDoc = doc(db, 'todo', id)
    deleteDoc(toDoDoc)
  }

  // deletando lembrete
  
    const handeDeleteLembrete= async(e, id)=>{
    e.preventDefault()
    const toDoDoc = doc(db, 'mensagens', id)
    deleteDoc(toDoDoc)
  }
//editandotodo

const[editToDO, setEditToDo]= useState(false);
const[editToDO2, setEditToDo2]= useState(false);
const[editMensagem, setEditMensagem]=useState('')
const[editMensagem2, setEditMensagem2]=useState('')
const [itemId, setItemId]=useState('')

const handleEditToDo = (e,mensagem, id)=>{
     e.preventDefault()
     setEditToDo(true)
     setItemId(id)
     setEditMensagem(mensagem)

}

const handleEditToDo2 = (e,mensagem, id)=>{
     e.preventDefault()
     setEditToDo2(true)
     setItemId(id)
     setEditMensagem(mensagem)

}

const handleUpdateToDo= async(e)=>{
    e.preventDefault()
    const toDoDoc = doc(db, 'todo', itemId)
    const newField = ({mensagemToDO:editMensagem2})
    await updateDoc(toDoDoc , newField)
    setEditToDo(false)
    setEditToDo2(false)
    setEditMensagem('')
    setEditMensagem2('')
  }

  // editando e updatando lembrete 

  const[editLembrete, setEditLembrete]= useState(false);
    const[editTextoLembrete, setEditTextoLembrete]=useState('')
    const[editTextoLembrete2, setEditTextoLembrete2]=useState('')


const handleEditLembrete = (e,mensagem, id)=>{
     e.preventDefault()
     setEditLembrete(true)
     setItemId(id)
     setEditTextoLembrete(mensagem)

}
const handleUpdateLembrete= async(e)=>{
    e.preventDefault()
    const toDoDoc = doc(db, 'mensagens', itemId)
    const newField = ({texto:editTextoLembrete2})
    await updateDoc(toDoDoc , newField)
    setEditLembrete(false)
    setEditTextoLembrete('')
    setEditTextoLembrete2('')
  }
     
                
                
  return (
    <div className='todoWarper'>
        <div className="lista">
            <div className="listafixo">
                <div className="texto">No Radar/Prop. Enviar - {listaToDo.filter((val)=>{if(val.data.status === true) {return val}}).length}</div>
                {ativo1?
                <div className="seta"><button className='fechar'  onClick={handleClose1}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button></div>
                :
                <div className="seta"><button className='abrir'  onClick={handleOpen1}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button></div> 
                }
            </div>
            <div  className={ativo1? 'listagem ativa' : 'listagem'}>
               <div className="listagemItens">
                {listaToDo.filter((val)=>{if(val.data.status === true) {return val}}).map((item, index)=>(
                    <div className="mensagens " key={index}>
                    <div className="mensagemTexto">
                        <div className="data">{new Date(item.data.dia).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</div>
                        <div className="texto">{item.data.mensagemToDO}</div>
                        <div className="usuario">{item.data.user}</div>
                    </div>
                    <div className="mensagemEdit">
                       <button onClick={(e)=>{handleEditToDo(e, item.data.mensagemToDO, item.id)}}>
                        <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5288 6.51201C29.1571 5.88377 29.1571 4.83669 28.5288 4.24066L24.7593 0.471185C24.1633 -0.157062 23.1162 -0.157062 22.488 0.471185L19.524 3.41911L25.5648 9.45994M0 22.9592V29H6.04083L23.8572 11.1675L17.8164 5.12665L0 22.9592Z"/>
                        </svg>
                       </button>
                       <button onClick={(e)=>{handeStatus1(e, item.id)}}>
                        <svg width="24.3" height="20" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5888 29L0 17.4112L5.28121 12.13L11.5888 18.4562L30.0264 0L35.3076 5.28121L11.5888 29Z"/>
                        </svg>
                       </button>
                       <button onClick={(e)=>{handeDeleteTodo(e, item.id)}}>
                        <svg width="20" height="20" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.83333 29C3.94722 29 3.18893 28.6848 2.55844 28.0543C1.92796 27.4238 1.61218 26.665 1.61111 25.7778V4.83333H0V1.61111H8.05555V0H17.7222V1.61111H25.7778V4.83333H24.1667V25.7778C24.1667 26.6639 23.8514 27.4227 23.2209 28.0543C22.5905 28.6858 21.8316 29.0011 20.9444 29H4.83333ZM8.05555 22.5556H11.2778V8.05555H8.05555V22.5556ZM14.5 22.5556H17.7222V8.05555H14.5V22.5556Z" />
                            </svg>
                        </button> 
                       
                    </div>
                 </div>

                ))}

               </div>
              {editToDO?
                <div className="botaoLista">
                        <form onSubmit={(e)=>{handleUpdateToDo(e)}}>
                            <textarea className='addTD' defaultValue={editMensagem} onChange={(e)=>{setEditMensagem2(e.target.value)}} ></textarea>
                            <input type="submit" value="ATUALIZAR" />
                            <button onClick={()=>{setEditToDo(false)}}>FECHAR</button>
                        </form>
                </div>
                : 
                <div className="botaoLista">
                    {addToDO?
                    <form onSubmit={handleAddToDO}>
                        <textarea className='addTD'onChange={(e)=>{setMensagemToDO(e.target.value)}} required></textarea>
                        
                        <input type="submit" value="ADICIONAR" />
                        <button onClick={()=>{setAddToDo(false)}}>FECHAR</button>
                        </form>
                        :
                        <button onClick={()=>{setAddToDo(true)}}>ADICIONAR</button>
                    }
                    
               </div>
              }
            </div>
        </div>
        <div className="lista">
            <div className="listafixo">
                <div className="texto">Prop. Enviadas/Monitorar - {listaToDo.filter((val)=>{if(val.data.status === false) {return val}}).length}</div>
                {ativo2?
                <div className="seta"><button className='fechar'  onClick={handleClose2}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button></div>
                :
                <div className="seta"><button className='abrir'  onClick={handleOpen2}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button></div> 
                }
            </div>
            <div  className={ativo2? 'listagem ativa' : 'listagem'}>
               <div className="listagemItens">
                {listaToDo.filter((val)=>{if(val.data.status === false) {return val}}).map((item, index)=>(
                    <div className="mensagens " key={index}>
                    <div className="mensagemTexto">
                        <div className="data">{new Date(item.data.dia).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</div>
                        <div className="texto">{item.data.mensagemToDO}</div>
                        <div className="usuario">{item.data.user}</div>
                    </div>
                    <div className="mensagemEdit">
                       <button onClick={(e)=>{handleEditToDo2(e, item.data.mensagemToDO, item.id)}}>
                        <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5288 6.51201C29.1571 5.88377 29.1571 4.83669 28.5288 4.24066L24.7593 0.471185C24.1633 -0.157062 23.1162 -0.157062 22.488 0.471185L19.524 3.41911L25.5648 9.45994M0 22.9592V29H6.04083L23.8572 11.1675L17.8164 5.12665L0 22.9592Z"/>
                        </svg>
                       </button>
                       <button  onClick={(e)=>{handeStatus2(e, item.id)}}>
                        <svg width="23.5" height="20" viewBox="0 0 37 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.1176 29.8601C30.9439 24.7652 27.2376 21.8743 23.9987 21.1874C20.7598 20.5005 17.6761 20.3967 14.7477 20.8761V30L1 15.1119L14.7477 1V9.67185C20.1627 9.7145 24.7664 11.6572 28.5585 15.5C32.3501 19.3428 34.5365 24.1295 35.1176 29.8601Z" stroke-width="2" stroke-linejoin="round"/>
                        </svg>
                       </button>
                       <button onClick={(e)=>{handeDeleteTodo(e, item.id)}}>
                        <svg width="20" height="20" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.83333 29C3.94722 29 3.18893 28.6848 2.55844 28.0543C1.92796 27.4238 1.61218 26.665 1.61111 25.7778V4.83333H0V1.61111H8.05555V0H17.7222V1.61111H25.7778V4.83333H24.1667V25.7778C24.1667 26.6639 23.8514 27.4227 23.2209 28.0543C22.5905 28.6858 21.8316 29.0011 20.9444 29H4.83333ZM8.05555 22.5556H11.2778V8.05555H8.05555V22.5556ZM14.5 22.5556H17.7222V8.05555H14.5V22.5556Z" />
                            </svg>
                       </button> 
                    </div>
                 </div>

                ))}
                {editToDO2?
                <div className="botaoLista">
                        <form onSubmit={(e)=>{handleUpdateToDo(e)}}>
                            <textarea className='addTD' defaultValue={editMensagem} onChange={(e)=>{setEditMensagem2(e.target.value)}} ></textarea>
                            <input type="submit" value="ATUALIZAR" />
                            <button onClick={()=>{setEditToDo2(false)}}>FECHAR</button>
                        </form>
                </div>
                : 
               ""
              }

               </div>
               
            </div>
            

           
        </div>
        <div className="lista">
            <div className="listafixo">
                <div className="texto">Lembretes/To Do's - {chat.length}</div>
                {ativo3?
                <div className="seta"><button className='fechar'  onClick={handleClose3}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button></div>
                :
                <div className="seta"><button className='abrir'  onClick={handleOpen3}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button></div> 
                }
            </div>
              <div  className={ativo3? 'listagem ativa' : 'listagem'}>
               <div className="listagemItens">
                {chat.map((item, index)=>(
                    <div className="mensagens " key={index}>
                    <div className="mensagemTexto">
                        <div className="data">{new Date(item.data.dia).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</div>
                        <div className="texto">{item.data.texto}</div>
                        <div className="usuario">{item.data.user}</div>
                    </div>
                    <div className="mensagemEdit">
                       <button onClick={(e)=>{handleEditLembrete(e, item.data.texto, item.id)}}>
                        <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5288 6.51201C29.1571 5.88377 29.1571 4.83669 28.5288 4.24066L24.7593 0.471185C24.1633 -0.157062 23.1162 -0.157062 22.488 0.471185L19.524 3.41911L25.5648 9.45994M0 22.9592V29H6.04083L23.8572 11.1675L17.8164 5.12665L0 22.9592Z"/>
                        </svg>
                       </button>
                       <button onClick={(e)=>{handeDeleteLembrete(e, item.id)}}>
                        <svg width="20" height="20" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.83333 29C3.94722 29 3.18893 28.6848 2.55844 28.0543C1.92796 27.4238 1.61218 26.665 1.61111 25.7778V4.83333H0V1.61111H8.05555V0H17.7222V1.61111H25.7778V4.83333H24.1667V25.7778C24.1667 26.6639 23.8514 27.4227 23.2209 28.0543C22.5905 28.6858 21.8316 29.0011 20.9444 29H4.83333ZM8.05555 22.5556H11.2778V8.05555H8.05555V22.5556ZM14.5 22.5556H17.7222V8.05555H14.5V22.5556Z" />
                            </svg>
                        </button> 
                    </div>
                 </div>

                ))}
             

               </div>
              {editLembrete?
                <div className="botaoLista">
                        <form onSubmit={(e)=>{handleUpdateLembrete(e)}}>
                            <textarea className='addTD' defaultValue={editTextoLembrete} onChange={(e)=>{setEditTextoLembrete2(e.target.value)}} ></textarea>
                            <input type="submit" value="ATUALIZAR" />
                            <button onClick={()=>{setEditLembrete(false)}}>FECHAR</button>
                        </form>
                </div>
                : 
                <div className="botaoLista">
                    {addLembrete?
                    <form onSubmit={handleAddLembrete}>
                        <textarea className='addTD'onChange={(e)=>{setTextoLembrete(e.target.value)}} required></textarea>
                        
                        <input type="submit" value="ADICIONAR" />
                        <button onClick={()=>{setAddLembrete(false)}}>FECHAR</button>
                        </form>
                        :
                        <button onClick={()=>{setAddLembrete(true)}}>ADICIONAR</button>
                    }
                    
               </div>
              }
            </div>
            
        </div>
      
       
        
    </div>
  )
}

export default ToDo