import React, {useState, useEffect} from 'react'
import { Link, useNavigate} from 'react-router-dom';
import {UserAuth} from '../../services/AuthContext'
import './partials.css';
import LogoMob from '../../media/logomob.webp';
import MenuNav from '../../media/menu.webp';
import MenuCreate from '../../media/create.webp';
import MenuClock from '../../media/clock.webp';
import MenuExit from '../../media/exit.webp';
import MenuFilter from '../../media/filter.webp';
import MenuTool from'../../media/tools.webp';

function SideBarTablesMob() {
     //states de visualizaçã0
   const [see, setSee]=useState(false);
   const [see1, setSee1]=useState(false);
   const [see2, setSee2]=useState(false);
   const [see3, setSee3]=useState(false);
   const [see4, setSee4]=useState(false);

 
   
 //funçoes de vizualização
   const handleToglle = ()=>{
     setSee(!see)
     setSee1(false)
     setSee2(false)
     setSee3(false)
     setSee4(false)
   }
 
   const handleToglle1 = ()=>{
     setSee(false)
     setSee1(!see1)
     setSee2(false)
     setSee3(false)
     setSee4(false)
   }
   const handleToglle2 = ()=>{
     setSee(false)
     setSee1(false)
     setSee2(!see2)
     setSee3(false)
     setSee4(false)
   }

   const handleToglle3 = ()=>{
       setSee(false)
       setSee1(false)
       setSee2(false)
       setSee3(!see3)
       setSee4(false) 
   }
   const handleToglle4 = ()=>{
       setSee(false)
       setSee1(false)
       setSee2(false)
       setSee3(false)
       setSee4(!see4)
   
   }
 
    //time zones
    const times = [
     {name: 'Lisboa', timeZ:'Europe/Lisbon'},
     {name:'Buenos Aires', timeZ:'America/Argentina/Buenos_Aires'},
     {name: 'São Paulo', timeZ:'America/Sao_Paulo'},
     {name: 'Rio de Janeiro', timeZ:'America/Sao_Paulo'},
     {name: 'Santiago', timeZ:'America/Santiago'},
     {name: 'Bogotá', timeZ:'America/Bogota'},
     {name:'San José', timeZ:'America/Costa_Rica'},
     {name:'Quito', timeZ:'America/Bogota'},
     {name:"Ciudad de Mexico", timeZ:'America/Mexico_City'},
     {name:"Asunción", timeZ:'America/Asuncion'},
     {name:"Lima", timeZ:'America/Lima'},
     {name:"Madrid", timeZ:'Europe/Madrid'},
     {name:"New York", timeZ:'America/New_York'},
     {name:"Los Angeles", timeZ:'America/Los_Angeles'},
     {name:"Caracas", timeZ:'America/Caracas'}
 ];
 
 //states para visualização
 
 const [clockZone, setClockZone]=useState('Europe/Lisbon');
 const [time, setTime]=useState(new Date());
 
 //efeito para atualizar
 useEffect(()=>{
     const timer = setInterval(()=>{
         setTime(new Date())
     },60000);
     return()=>{
         clearInterval(timer);
     }
 },[]);
 
 //função para visualizar horario formatada
 const fomatedTime = time.toLocaleTimeString('en-US', {
     timeZone:clockZone,
     hour: '2-digit',
     minute: '2-digit',
 })
 
 //funçoes log out
 
 const navigate = useNavigate();
     const{logout} = UserAuth();
     const handleLogout = async()=>{
         try{
             await logout();
             navigate('/login')
         }catch(e){
             console.log(e.message)
         }
     }
  return (
    <>
    <div className='SBtopLogo'>
          <img className='logoMob' src={LogoMob} alt="logo" width={'100%'} height={'auto'} />
      </div>
      <div className='SBNavMob' onClick={handleToglle} >
          <img src={MenuNav} alt="Menu" width={'100%'} height={'auto'} />
      </div>
      <div className={see? 'SBNavMenuMob  SBNavMenuMobActive' : 'SBNavMenuMob'}>
          <div className='SBsectionName'>geral</div>
          <div className="SBContent">
            <Link to='/'>PROJEtos</Link>
            <Link to='/clientes/ativos'>CLIENTeS</Link>
            <Link to='/prospeccoes'>Prospecções</Link>
            <Link to='/analistas'>Analistas</Link>
            <Link to='/fornecedores'>Fornecedores</Link>
          </div>
      </div>
      <div className='SBNavMob' onClick={handleToglle3} >
          <img src={MenuFilter} alt="Menu" width={'100%'} height={'auto'} />
      </div>
      <div className={see3? 'SBNavMenuMob  SBNavMenuMobActive' : 'SBNavMenuMob'}>
      <div className='SBsectionName'>FILTROS DE Tabelas</div>
            <div className="SBContent">
                <Link to='/tabela/projetos'>Projetos</Link>
                <Link to='/tabela/projetoperiodo'>Projetos por período</Link>
                <Link to='/tabela/pagamentoanalistas'>Pagamentos Analistas</Link>
                <Link to='/tabela/pagamentolideres'>Pagamentos Líderes</Link>
                <Link to='/tabela/clientes'>Clientes</Link>
                <Link to='/tabela/analistas'>Analistas</Link>
            </div>
      </div>
      <div className='SBNavMob' onClick={handleToglle1}>
          <img src={MenuCreate} alt="Menu" width={'100%'} height={'auto'} />
      </div>
      <div className={see1? 'SBNavMenuMob  SBNavMenuMobActive' : 'SBNavMenuMob'} >
          <div className='SBsectionName'>criar</div>
          <div className="SBContent">
            <Link to='/novoprojeto'>novo projeto</Link>
            <Link to='/novocliente'>novo cliente</Link>
            <Link to='/novaprospeccao'>nova Prospecção</Link>
            <Link to='/novocolaborador'>novo colaborador</Link>
          </div>
      </div>
      <div className='SBNavMob' onClick={handleToglle4}>
          <img src={MenuTool} alt="Menu" width={'100%'} height={'auto'} />
      </div>
      <div className={see4? 'SBNavMenuMob  SBNavMenuMobActive' : 'SBNavMenuMob'} >
          <div className='SBsectionName'>ferramentas</div>
          <div className="SBContent">
          <Link to='/calculadora'>calculadora</Link>
          <Link to='/precificador'>precificador</Link>
          <Link to='/tabela/projetos'>tabelas</Link>.
          <a href="https://analises.nexointell.com/" target="_blank" rel="noopener noreferrer">Estatísticas</a>
            <Link to='/email/clientes'>enviar email</Link>
            <a href="https://meet.google.com/cqn-efgb-pdh" target="_blank" rel="noopener noreferrer">Sala Antônio</a>
            <a href="https://meet.google.com/oay-qrtw-nfq" target="_blank" rel="noopener noreferrer">Sala Luis</a>
            <a href="https://docs.google.com/spreadsheets/d/1WgGrlqvHQ6DiMsZgRAGLTU4nvSg7B757XgiwN4ESsEk/edit#gid=645049157" target="_blank" rel="noopener noreferrer">feriados</a>
            
          </div>
      </div>
      <div className='SBNavMob' onClick={handleToglle2}>
          <img src={MenuClock} alt="Menu" width={'100%'} height={'auto'} />
      </div>
      <div className={see2? 'SBNavMenuMob  SBNavMenuMobActive' : 'SBNavMenuMob'} >
          <div className='SBsectionName'>fusos horários</div>
          <div className="SBClockContent">
              <div className='SBclockTimeDisplay'>{fomatedTime}</div>
                  <select className='SBclockCityDisplay' name="clock" id="clock" onChange={e => setClockZone(e.target.value)}>
                      {times.map((item,index)=>(
                          <option key={index} value={item.timeZ}>{item.name}</option>
                      ))}
                  </select>
              </div>
      </div>
      <div className='SBNavMob' onClick={handleLogout}>
          <img src={MenuExit} alt="Menu" width={'100%'} height={'auto'} />
      </div>
      </>
  )
}

export default SideBarTablesMob