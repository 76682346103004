import React from 'react'
import { Link } from 'react-router-dom'

function Utils() {
  return (
    <div className='SBNav'>
        <div className='SBsectionName'>FERRAMENTAS</div>
        <div className="SBContent">
        <Link to='/calculadora'>calculadora</Link>
          <Link to='/precificador'>precificador</Link>
          <Link to='/tabela/projetos'>tabelas</Link>
          <a href="https://analises.nexointell.com/" target="_blank" rel="noopener noreferrer">Estatísticas</a>
            <Link to='/email/clientes'>Enviar Emails</Link>
            <a href="https://docs.google.com/spreadsheets/d/1WgGrlqvHQ6DiMsZgRAGLTU4nvSg7B757XgiwN4ESsEk/edit#gid=645049157" target="_blank" rel="noopener noreferrer">Feriados</a>
            
        </div>

    </div>
  )
}

export default Utils