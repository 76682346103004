import React from "react";
import {Routes, Route} from 'react-router-dom';
import { AuthContextProvider } from "../services/AuthContext";
import { ProtectedRoutesAdm } from "./ProtectedRoutes";
import Login from '../pages/Login/Login';
import Unfound from "../pages/Unfound/Unfound";
import UpClients from "../pages/Clients/UpClients";
import ListClients from "../pages/Clients/ListClients";
import Pricing from "../pages/Pricing/Pricing";
import ListAllCollaborators from "../pages/Collaborators/ListAllCollaborators";
import UpCollaborator from "../pages/Collaborators/UpCollaborator";
import UpProjects from "../pages/Projects/UpProjects";
import Client from "../pages/Clients/Client";
import ListAnalystCollab from "../pages/Collaborators/ListAnalystCollab";
import ListSupplierCollab from "../pages/Collaborators/ListSupplierCollab";
import Collabortor from "../pages/Collaborators/Collabortor";
import ListCollabProjectLeader from "../pages/Collaborators/ListCollabProjectLeader";
import ListCollabHumint from "../pages/Collaborators/ListCollabHumint";
import ListCollabDocumentsAnalysis from "../pages/Collaborators/ListCollabDocumentsAnalysis";
import ListCollabSurveillance from "../pages/Collaborators/ListCollabSurveillance";
import ListCollabTranslatorReviewer from "../pages/Collaborators/ListCollabTranslatorReviewer";
import ListCollabTechnology from "../pages/Collaborators/ListCollabTechnology";
import ListCollabAccounting from "../pages/Collaborators/ListCollabAccounting";
import ListCollabVirtualOffice from "../pages/Collaborators/ListCollabVirtualOffice";
import ListCollabDataBank from "../pages/Collaborators/ListCollabDataBank";
import ListCollabBank from "../pages/Collaborators/ListCollabBank";
import EmaiCLients from "../pages/Email/EmaiCLients";
import EmailClientsBrazil from "../pages/Email/EmailClientsBrazil";
import EmailClientsGlobal from "../pages/Email/EmailClientsGlobal";
import EmailSupplier from "../pages/Email/EmailSupplier";
import EmailAnalyst from "../pages/Email/EmailAnalyst";
import EmailAnalystBrazil from "../pages/Email/EmailAnalystBrazil";
import EmailAnalystGlobal from "../pages/Email/EmailAnalystGlobal";
import EmailSupplierBrazil from "../pages/Email/EmailSupplierBrazil";
import EmailSupplierGlobal from "../pages/Email/EmailSupplierGlobal";
import Project from "../pages/Projects/Project";
import OnGoing from "../pages/Projects/OnGoing";
import Delivered from "../pages/Projects/Delivered";
import ToBill from "../pages/Projects/ToBill";
import ToPay from "../pages/Projects/ToPay";
import ToRecive from "../pages/Projects/ToRecive";
import Recived from "../pages/Projects/Recived";
import ProjectToPay from "../pages/Projects/ProjectToPay";
import AllProjects from "../pages/Projects/AllProjects";
import Payed from "../pages/Projects/Payed";
import Finihed from "../pages/Projects/Finihed";
import StandBy from "../pages/Projects/StandBy";
import Analises from "../pages/Projects/Analises";
import ClientActive from "../pages/Clients/ClientActive";
import ClientInactive from  "../pages/Clients/ClientInactive"
import UnfoundClient from "../pages/Unfound/UnfoundClient";
import Calculadora from "../pages/Calculadora/Calculadora";
import Prospect from "../pages/Prospects/Prospect";
import ListProspects from "../pages/Prospects/ListProspects";
import UpProspect from "../pages/Prospects/UpProspect";
import EmailProspect from "../pages/Email/EmailProspect";
import EmailProspectBrazil from "../pages/Email/EmailProspectBrazil";
import EmailProspectGlobal from "../pages/Email/EmailProspectGlobal";
import EmailClientsAndProspects from "../pages/Email/EmailClientsAndProspects";
import EmailClientsAndProspectsBrazil from "../pages/Email/EmailClientsAndProspectsBrazil";
import EmailClientsAndProspectsGlobal from "../pages/Email/EmailClientsAndProspectsGlobal";
import ProjectTable from "../pages/Tables/ProjectTable";
import ClientsTable from "../pages/Tables/ClientsTable";
import AnalistsTable from "../pages/Tables/AnalistsTable";
import OnGoingTable from "../pages/Tables/OnGoingTable";
import ToPayTable from "../pages/Tables/ToPayTable";
import PayedTable from "../pages/Tables/PayedTable";
import ClientsProjectsTable from "../pages/Tables/ClientsProjectsTable";
import FilteredProjects from "../pages/Tables/FilteredProjects";
import PagamentoAnalistas from "../pages/Tables/PagamentoAnalistas";
import PagamentoLideres from "../pages/Tables/PagamentoLideres";
import AnalistasAPagar from "../pages/Projects/AnalistasAPagar";
import LideresAPagar from "../pages/Projects/LideresAPagar";
import FornecedoresAPagar from "../pages/Projects/FornecedoresAPagar";

export const RouteList =()=>{

    return(
        <AuthContextProvider>
            <Routes>
                
                <Route path="/novocliente" element={
                    <ProtectedRoutesAdm>
                    <UpClients />
                    </ProtectedRoutesAdm>
              
                } />

                <Route path="/clientes" element={
                    <ProtectedRoutesAdm>
                    <ListClients />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/clientes/ativos" element={
                    <ProtectedRoutesAdm>
                    <ClientActive />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/clientes/inativos" element={
                    <ProtectedRoutesAdm>
                    <ClientInactive />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/cliente/:id" element={
                    <ProtectedRoutesAdm>
                    <Client />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/novocolaborador" element={
                    <ProtectedRoutesAdm>
                    <UpCollaborator />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/colaboradores" element={
                    <ProtectedRoutesAdm>
                    <ListAllCollaborators />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/analistas" element={
                    <ProtectedRoutesAdm>
                    <ListAnalystCollab />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/analistas/liderdeprojeto" element={
                    <ProtectedRoutesAdm>
                    <ListCollabProjectLeader />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/analistas/pesquisador" element={
                    <ProtectedRoutesAdm>
                    <ListCollabHumint />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/analistas/pesquisadordedocumentos" element={
                    <ProtectedRoutesAdm>
                    <ListCollabDocumentsAnalysis />
                    </ProtectedRoutesAdm>
              
                } />
                
                <Route path="/analistas/seguranca" element={
                    <ProtectedRoutesAdm>
                    <ListCollabSurveillance />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/analistas/tradutorrevisor" element={
                    <ProtectedRoutesAdm>
                    <ListCollabTranslatorReviewer />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/fornecedores/tecnologia" element={
                    <ProtectedRoutesAdm>
                    <ListCollabTechnology />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/fornecedores/contabilidade" element={
                    <ProtectedRoutesAdm>
                    <ListCollabAccounting />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/fornecedores/escritoriovirtual" element={
                    <ProtectedRoutesAdm>
                    <ListCollabVirtualOffice />
                    </ProtectedRoutesAdm>
              
                } />
               
                <Route path="/fornecedores/banco" element={
                    <ProtectedRoutesAdm>
                    <ListCollabBank />
                    </ProtectedRoutesAdm>
              
                } />
                
                <Route path="/fornecedores/bancodados" element={
                    <ProtectedRoutesAdm>
                    <ListCollabDataBank />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/fornecedores" element={
                    <ProtectedRoutesAdm>
                    <ListSupplierCollab />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/email/prospeccoes" element={
                    <ProtectedRoutesAdm>
                    <EmailProspect />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/prospeccoes/brasil" element={
                    <ProtectedRoutesAdm>
                    <EmailProspectBrazil />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/prospeccoes/global" element={
                    <ProtectedRoutesAdm>
                    <EmailProspectGlobal />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/clienteseprospeccoes" element={
                    <ProtectedRoutesAdm>
                    <EmailClientsAndProspects />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/email/clienteseprospeccoes/brasil" element={
                    <ProtectedRoutesAdm>
                    <EmailClientsAndProspectsBrazil />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/email/clienteseprospeccoes/global" element={
                    <ProtectedRoutesAdm>
                    <EmailClientsAndProspectsGlobal />
                    </ProtectedRoutesAdm>
              
                } />
                
                <Route path="/email/clientes" element={
                    <ProtectedRoutesAdm>
                    <EmaiCLients />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/clientes/brasil" element={
                    <ProtectedRoutesAdm>
                    <EmailClientsBrazil />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/clientes/global" element={
                    <ProtectedRoutesAdm>
                    <EmailClientsGlobal />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/analistas" element={
                    <ProtectedRoutesAdm>
                    <EmailAnalyst />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/analistas/brasil" element={
                    <ProtectedRoutesAdm>
                    <EmailAnalystBrazil />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/analistas/global" element={
                    <ProtectedRoutesAdm>
                    <EmailAnalystGlobal />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/fornecedores" element={
                    <ProtectedRoutesAdm>
                    <EmailSupplier />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/email/fornecedores/brasil" element={
                    <ProtectedRoutesAdm>
                    <EmailSupplierBrazil />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/email/fornecedores/global" element={
                    <ProtectedRoutesAdm>
                    <EmailSupplierGlobal />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/colaborador/:id" element={
                    <ProtectedRoutesAdm>
                    <Collabortor />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/novoprojeto" element={
                    <ProtectedRoutesAdm>
                    <UpProjects />
                    </ProtectedRoutesAdm>
              
                } />
          
           
                  <Route path="/" element={
                    <ProtectedRoutesAdm>
                    <OnGoing />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="projetos/entregues" element={
                    <ProtectedRoutesAdm>
                    <Delivered />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projeto/:id" element={
                    <ProtectedRoutesAdm>
                    <Project />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/projetos/acobrar" element={
                    <ProtectedRoutesAdm>
                    <ToBill />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/apagar" element={
                    <ProtectedRoutesAdm>
                    <ToPay />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/projetos/areceber" element={
                    <ProtectedRoutesAdm>
                    <ToRecive />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/depositado" element={
                    <ProtectedRoutesAdm>
                    <Recived />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projeto/pagamento/:id" element={
                    <ProtectedRoutesAdm>
                    <ProjectToPay />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/todos" element={
                    <ProtectedRoutesAdm>
                    <AllProjects />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/projetos/pago" element={
                    <ProtectedRoutesAdm>
                    <Payed />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/finalizado" element={
                    <ProtectedRoutesAdm>
                    <Finihed />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/empausa" element={
                    <ProtectedRoutesAdm>
                    <StandBy />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/emanalise" element={
                    <ProtectedRoutesAdm>
                    <Analises />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/analistasapagar" element={
                    <ProtectedRoutesAdm>
                    <AnalistasAPagar />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/projetos/lideresapagar" element={
                    <ProtectedRoutesAdm>
                    <LideresAPagar />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/projetos/fornecedoresapagar" element={
                    <ProtectedRoutesAdm>
                    <FornecedoresAPagar />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/precificador" element={
                    <ProtectedRoutesAdm>
                    <Pricing />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/calculadora" element={
                    <ProtectedRoutesAdm>
                    <Calculadora />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/novaprospeccao" element={
                    <ProtectedRoutesAdm>
                    <UpProspect />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/prospeccao/:id" element={
                    <ProtectedRoutesAdm>
                    <Prospect />
                    </ProtectedRoutesAdm>
              
                } />
                   <Route path="/prospeccoes" element={
                    <ProtectedRoutesAdm>
                    <ListProspects />
                    </ProtectedRoutesAdm>
              
                } />
                 <Route path="/tabela/projetos" element={
                    <ProtectedRoutesAdm>
                    <ProjectTable />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/tabela/clientes" element={
                    <ProtectedRoutesAdm>
                    <ClientsTable />
                    </ProtectedRoutesAdm>
              
                } />
                  <Route path="/tabela/analistas" element={
                    <ProtectedRoutesAdm>
                    <AnalistsTable />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/tabela/emandamento/analista/:id" element={
                    <ProtectedRoutesAdm>
                    <OnGoingTable />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/tabela/apagar/analista/:id" element={
                    <ProtectedRoutesAdm>
                    <ToPayTable />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/tabela/pago/analista/:id" element={
                    <ProtectedRoutesAdm>
                    <PayedTable />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/tabela/clientes/projetos/:id" element={
                    <ProtectedRoutesAdm>
                    <ClientsProjectsTable />
                    </ProtectedRoutesAdm>
              
                } />
                  <Route path="/tabela/projetoperiodo" element={
                    <ProtectedRoutesAdm>
                    <FilteredProjects />
                    </ProtectedRoutesAdm>
              
                } />

                    <Route path="/tabela/pagamentoanalistas" element={
                    <ProtectedRoutesAdm>
                    <PagamentoAnalistas />
                    </ProtectedRoutesAdm>
              
                } />
                <Route path="/tabela/pagamentolideres" element={
                    <ProtectedRoutesAdm>
                    <PagamentoLideres />
                    </ProtectedRoutesAdm>
              
                } />

                
                <Route path="/login" element={<Login/>} />
                <Route path="/*" element={<Unfound/>} />
                <Route path="cliente/*" element={<UnfoundClient/>} />
                
            </Routes>
        </AuthContextProvider>
    );

}